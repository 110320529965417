import { isStorageAvailable } from '~/utils/analytics';
export default defineNuxtRouteMiddleware((to) => {
  const { runningTests } = useRuntimeConfig().public;

  if (runningTests) {
    return;
  }
  const URL_TO_US_CA_MX = '/company/careers';
  const COMPANY_LIST = [
    '1b338bf9435a613',
    '653e12ccd3820db',
    '235526d2f23265f',
  ]; // provided by 6s admin
  const SEGMENT_US_CA_MX = 767093; // provided by 6s admin

  if (import.meta.server) return; // don't run on server

  if (!isStorageAvailable('localStorage')) return; // don't run if localstorage can't be set

  const { analyticsTrack } = useAnalytics();

  function waitForLocalStorageItem(key, timeout = 5000) {
    return new Promise((resolve) => {
      const startTime = Date.now();

      const interval = setInterval(() => {
        const value = localStorage.getItem(key);
        if (value) {
          clearInterval(interval); // Stop polling once the value is found
          resolve(value); // Return the value
        } else if (Date.now() - startTime >= timeout) {
          clearInterval(interval); // Stop polling after the timeout
        }
      }, 100); // Check every 100ms
    });
  }

  if (!to.fullPath.includes('careers')) {
    waitForLocalStorageItem('_6senseCompanyDetails', 5000) // wait for localstorage
      .then((companyDetails) => {
        // Run the logic once the item is found
        const parsedDetails = JSON.parse(companyDetails);

        const foundSegments = parsedDetails.segments.ids;

        // Check the segment ID and redirect accordingly
        if (
          foundSegments.includes(SEGMENT_US_CA_MX) ||
          COMPANY_LIST.includes(parsedDetails.company.companyId)
        ) {
          analyticsTrack('Competitor Redirected', {
            'Competitor ID': parsedDetails.company.companyId,
            'Competitor Company': parsedDetails.company.name,
          });
          return navigateTo(URL_TO_US_CA_MX, {
            external: true,
          }); // external true so page re-fetches
        }
        // future state
        // else if (parsedDetails.segmentId === '766465') {
        //   return navigateTo('URL_TO_EMEA');
        // }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
  // Proceed to the intended page if no redirection occurs
});
